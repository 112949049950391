import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

// Modified parseSearchInput function to better handle punctuation in search terms
const parseSearchInput = (input) => {
  if (!input || input.trim() === '') return [];
  
  // Normalize hyphens (treat different types as the same)
  input = input.replace(/[–—]/g, '-');
  
  const terms = [];
  let inQuote = false;
  let quoteChar = '';
  let currentTerm = '';
  
  // Process each character in the input
  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    
    // Handle quote characters
    if ((char === '"' || char === "'") && (i === 0 || input[i-1] !== '\\')) {
      if (!inQuote) {
        // Starting a quoted phrase
        inQuote = true;
        quoteChar = char;
        // If we have collected any non-quoted text, process it
        if (currentTerm.trim()) {
          // Consider the entire text as a phrase if it contains spaces
          const trimmedTerm = currentTerm.trim();
          if (trimmedTerm.includes(' ') || trimmedTerm.includes('-')) {
            terms.push({
              term: trimmedTerm.toLowerCase(),
              isWholeWord: false,
              isPhrase: true
            });
          } else {
            terms.push({
              term: trimmedTerm.toLowerCase(),
              isWholeWord: false
            });
          }
          currentTerm = '';
        }
      } else if (char === quoteChar) {
        // Ending a quoted phrase
        inQuote = false;
        if (currentTerm.trim()) {
          const phrase = currentTerm.trim();
          terms.push({
            term: phrase.toLowerCase(),
            isWholeWord: true,
            isPhrase: true
          });
        }
        currentTerm = '';
      } else {
        // A different quote character inside a quote
        currentTerm += char;
      }
    } else if (inQuote) {
      // Within quotes, add all characters to the current term
      currentTerm += char;
    } else if (/\s/.test(char)) {
      // Outside quotes, space terminates a word
      if (currentTerm.trim()) {
        terms.push({
          term: currentTerm.trim().toLowerCase(),
          isWholeWord: false
        });
      }
      currentTerm = '';
    } else {
      // Regular character outside quotes
      currentTerm += char;
    }
  }
  
  // Handle any remaining term
  if (currentTerm.trim()) {
    if (inQuote) {
      // Unclosed quote
      terms.push({
        term: currentTerm.trim().toLowerCase(),
        isWholeWord: true,
        isPhrase: true
      });
    } else {
      // Check if it contains spaces or hyphens (which would make it a phrase)
      const trimmedTerm = currentTerm.trim();
      if (trimmedTerm.includes(' ') || trimmedTerm.includes('-')) {
        terms.push({
          term: trimmedTerm.toLowerCase(),
          isWholeWord: false,
          isPhrase: true
        });
      } else {
        terms.push({
          term: trimmedTerm.toLowerCase(),
          isWholeWord: false
        });
      }
    }
  }
  
  return terms.filter(termObj => termObj.term.length > 0);
};

function isOnlyPunctuation(text) {
  return /^[.,;:!?\-–—]$/.test(text.trim());
}

const searchForPhrase = (phrase, textElements, pageIndex, pageNumber, page, textLayer, debug = false) => {
  if (!phrase || textElements.length < 2) {
    return []; // Not enough elements to span
  }
  
  // Normalize hyphens in the phrase
  phrase = phrase.replace(/[–—]/g, '-');
  
  const results = [];
  // Split by whitespace, preserving hyphens as part of words when they're connected
  // But treating standalone hyphens as separate tokens
  const words = [];
  const rawWords = phrase.toLowerCase().split(/\s+/);
  
  // Process each word to handle hyphens properly
  for (let i = 0; i < rawWords.length; i++) {
    const word = rawWords[i];
    
    // Check if the word is just a hyphen
    if (word === '-') {
      words.push(word);
      continue;
    }
    
    // Check if the word contains a hyphen
    if (word.includes('-')) {
      // If hyphen is at start or end, include it with the word
      if (word.startsWith('-')) {
        words.push(word);
      } else if (word.endsWith('-')) {
        words.push(word);
      } else {
        // If hyphen is in the middle, split it based on context
        // Here we're treating hyphenated words as separate parts
        // You can modify this logic if you want to keep hyphenated words together
        const parts = word.split('-');
        for (let j = 0; j < parts.length; j++) {
          words.push(parts[j]);
          if (j < parts.length - 1) {
            words.push('-');
          }
        }
      }
    } else {
      words.push(word);
    }
  }
  
  if (debug) console.log(`Searching for phrase with ${words.length} words: ${words.join('|')}`);
  
  // Look for the first word, then check if subsequent words follow
  for (let startElementIndex = 0; startElementIndex < textElements.length; startElementIndex++) {
    const firstElement = textElements[startElementIndex];
    const firstElementText = firstElement.textContent.toLowerCase();
    
    // Skip elements that are just spaces
    if (!firstElementText.trim()) continue;
    
    // Skip if the first element is punctuation and our first word isn't punctuation
    if (isOnlyPunctuation(firstElementText) && !isOnlyPunctuation(words[0])) continue;
    
    // Handle punctuation in the first word
    let firstWord = words[0];
    let firstWordRegex;
    
    let firstWordMatch;
    
    if (isOnlyPunctuation(firstWord)) {
      // If the first word is just punctuation, check for exact match
      firstWordMatch = firstElementText === firstWord ? { index: 0 } : null;
    } else {
      // For normal words, use regex with word boundaries
      if (/[.,;:!?-–—]$/.test(firstWord)) {
        const withoutPunctuation = firstWord.replace(/[.,;:!?-–—]$/, '');
        firstWordRegex = new RegExp(`\\b${escapeRegExp(withoutPunctuation)}[.,;:!?-–—]?\\b`, 'i');
      } else {
        firstWordRegex = new RegExp(`\\b${escapeRegExp(firstWord)}\\b`, 'i');
      }
      
      firstWordMatch = firstWordRegex.exec(firstElementText);
    }
    
    if (!firstWordMatch) continue;
    
    if (debug) console.log(`Found first word "${words[0]}" at element index ${startElementIndex}`);
    
    // Initialize tracking variables
    let allWordsFound = true;
    let matchedElements = [{
      element: firstElement,
      word: words[0],
      index: firstWordMatch.index,
      charIndex: firstWordMatch.index
    }];
    
    let currentElementIndex = startElementIndex + 1;
    
    // Look for each subsequent word
    for (let wordIndex = 1; wordIndex < words.length; wordIndex++) {
      const currentWord = words[wordIndex];
      let wordFound = false;
      
      // Look ahead up to 10 elements for the next word (increased from 5)
      const maxLookahead = 10;
      const lookaheadLimit = Math.min(currentElementIndex + maxLookahead, textElements.length);
      
      for (let lookIndex = currentElementIndex; lookIndex < lookaheadLimit; lookIndex++) {
        const element = textElements[lookIndex];
        const elementText = element.textContent.toLowerCase();
        
        // Skip empty or whitespace-only elements
        if (!elementText.trim()) {
          continue;
        }
        
        // Special handling for punctuation elements
        if (isOnlyPunctuation(elementText)) {
          // If current word is punctuation and matches the element text
          if (currentWord === elementText || 
              // Handle different types of hyphens
              (currentWord === '-' && (elementText === '–' || elementText === '—')) ||
              ((currentWord === '–' || currentWord === '—') && elementText === '-')) {
            
            matchedElements.push({
              element: element,
              word: elementText,
              index: 0,
              charIndex: 0,
              isPunctuation: true
            });
            
            wordFound = true;
            currentElementIndex = lookIndex + 1;
            break;
          }
          
          // If element is punctuation but doesn't match current word, include it and continue search
          matchedElements.push({
            element: element,
            word: elementText,
            index: 0,
            charIndex: 0,
            isPunctuation: true
          });
          continue; // Continue to next element without advancing wordIndex
        }
        
        // If current word is punctuation but element isn't, continue searching
        if (isOnlyPunctuation(currentWord)) continue;
        
        // Handle regular words with possible punctuation
        let wordRegex;
        if (/[.,;:!?-–—]$/.test(currentWord)) {
          const withoutPunctuation = currentWord.replace(/[.,;:!?-–—]$/, '');
          wordRegex = new RegExp(`\\b${escapeRegExp(withoutPunctuation)}[.,;:!?-–—]?\\b`, 'i');
        } else {
          wordRegex = new RegExp(`\\b${escapeRegExp(currentWord)}\\b`, 'i');
        }
        
        const wordMatch = wordRegex.exec(elementText);
        
        if (wordMatch) {
          if (debug) console.log(`Found word "${currentWord}" at element index ${lookIndex}`);
          
          matchedElements.push({
            element: element,
            word: currentWord,
            index: wordMatch.index,
            charIndex: wordMatch.index
          });
          
          wordFound = true;
          currentElementIndex = lookIndex + 1;
          break;
        }
        
        // If element has substantial text that doesn't match, it may break the phrase
        // But be more lenient with short elements - they might be parts of words
        if (elementText.length > 3 && !elementText.toLowerCase().includes(currentWord.toLowerCase().replace(/[.,;:!?\-–—]$/, ''))) {
          break;
        }
      }
      
      if (!wordFound) {
        allWordsFound = false;
        if (debug) console.log(`Word "${currentWord}" not found near element index ${currentElementIndex}`);
        break;
      }
    }
    
    // Filter out punctuation to count actual words
    const actualWords = words.filter(word => !isOnlyPunctuation(word));
    const matchedActualWords = matchedElements.filter(el => !el.isPunctuation);
    
    // If we found all words in sequence
    if (allWordsFound && matchedActualWords.length >= actualWords.length) {
      try {
        const textLayerRect = textLayer.getBoundingClientRect();
        
        // Group elements by their vertical position (line)
        const elementsByLine = [];
        let currentLine = [];
        let currentTop = null;
        
        // Sort elements by their vertical position
        matchedElements.sort((a, b) => {
          const rectA = a.element.getBoundingClientRect();
          const rectB = b.element.getBoundingClientRect();
          return rectA.top - rectB.top;
        });
        
        // Group elements by line
        matchedElements.forEach(matched => {
          const elementRect = matched.element.getBoundingClientRect();
          
          // If this is the first element or on the same line (within tolerance)
          if (currentTop === null || Math.abs(elementRect.top - currentTop) < 5) {
            if (currentTop === null) {
              currentTop = elementRect.top;
            }
            currentLine.push(matched);
          } else {
            // New line detected
            if (currentLine.length > 0) {
              elementsByLine.push([...currentLine]);
            }
            currentLine = [matched];
            currentTop = elementRect.top;
          }
        });
        
        // Add the last line if it exists
        if (currentLine.length > 0) {
          elementsByLine.push(currentLine);
        }
        
        // Generate a unique match ID for this phrase match
        const matchId = `match-${pageIndex}-${startElementIndex}`;
        
        // Create result for each line
        elementsByLine.forEach((lineElements, lineIndex) => {
          // Skip empty lines
          if (lineElements.length === 0) return;
          
          // Calculate bounds for this line
          let minLeft = Infinity;
          let minTop = Infinity;
          let maxRight = -Infinity;
          let maxBottom = -Infinity;
          
          // Calculate bounds for all elements in this line
          lineElements.forEach((matched, elemIndex) => {
            const elementRect = matched.element.getBoundingClientRect();
            
            // For first element, adjust left based on match position
            if (elemIndex === 0) {
              const elementText = matched.element.textContent;
              const charWidth = elementRect.width / Math.max(elementText.length, 1);
              const wordStartOffset = matched.index * charWidth;
              minLeft = Math.min(minLeft, elementRect.left + wordStartOffset);
            } else {
              minLeft = Math.min(minLeft, elementRect.left);
            }
            
            // For last element, adjust right based on match position
            if (elemIndex === lineElements.length - 1) {
              const elementText = matched.element.textContent;
              const charWidth = elementRect.width / Math.max(elementText.length, 1);
              
              if (lineIndex === elementsByLine.length - 1 && 
                  elemIndex === lineElements.length - 1) {
                const wordEndOffset = (matched.index + matched.word.length) * charWidth;
                maxRight = Math.max(maxRight, elementRect.left + wordEndOffset);
              } else {
                maxRight = Math.max(maxRight, elementRect.right);
              }
            } else {
              maxRight = Math.max(maxRight, elementRect.right);
            }
            
            minTop = Math.min(minTop, elementRect.top);
            maxBottom = Math.max(maxBottom, elementRect.bottom);
          });
          
          // Get text for this line
          let lineText = '';
          lineElements.forEach(matched => {
            if (matched.isPunctuation) {
              lineText += matched.element.textContent;
            } else {
              lineText += (lineText ? ' ' : '') + matched.element.textContent;
            }
          });
          
          // Create a result for this line
          results.push({
            pageIndex: pageIndex,
            pageNumber: pageNumber,
            text: lineText,
            itemIndex: lineIndex === 0 ? startElementIndex : -1,
            charIndex: lineIndex === 0 ? matchedElements[0].charIndex : 0,
            left: minLeft - textLayerRect.left,
            top: minTop - textLayerRect.top,
            width: maxRight - minLeft,
            height: maxBottom - minTop,
            element: lineElements[0].element,
            page: page,
            textLayer: textLayer,
            searchTerm: phrase.toLowerCase(),
            isPhrasePiece: true,
            isMultiLine: elementsByLine.length > 1,
            lineIndex: lineIndex,
            totalLines: elementsByLine.length,
            matchId: matchId,
            isFirstLine: lineIndex === 0,
            matchedElements: lineElements
          });
        });
      } catch (error) {
        console.error('Error creating phrase search result:', error);
      }
    }
  }
  
  return results;
};

// Helper function to escape special characters in regex
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const sortSearchResultsInReadingOrder = (results) => {
  if (!results || results.length === 0) return [];
  
  // Create a copy to avoid modifying the original array
  return [...results].sort((a, b) => {
    // First sort by page
    if (a.pageNumber !== b.pageNumber) {
      return a.pageNumber - b.pageNumber;
    }
    
    // Then sort by vertical position (top)
    // Use a small threshold for items that are approximately on the same line
    const verticalThreshold = 5; // 5px threshold for same line
    if (Math.abs(a.top - b.top) > verticalThreshold) {
      return a.top - b.top;
    }
    
    // For items on the same line, sort by horizontal position (left to right)
    return a.left - b.left;
  });
};

// Main search function that uses the phrase search
const searchInTextLayers = (containerElement, searchTerm, debug = false) => {
  if (!containerElement || !searchTerm) return [];
  
  const results = [];
  
  // Parse the search input to get all terms with their matching requirements
  const searchTermObjects = parseSearchInput(searchTerm);
  
  // If no valid search terms, return empty results
  if (searchTermObjects.length === 0) return [];
  
  // Get all pages
  const pages = containerElement.querySelectorAll('.react-pdf__Page');
  if (debug) console.log(`Searching across ${pages.length} pages`);
  
  let totalTextElements = 0;
  
  // Search on each page
  pages.forEach((page, pageIndex) => {
    const pageNumber = parseInt(page.getAttribute('data-page-number') || '0');
    if (isNaN(pageNumber)) return;
    
    // Get text layer
    const textLayer = page.querySelector('.react-pdf__Page__textContent');
    if (!textLayer) {
      if (debug) console.log(`No text layer found for page ${pageNumber}`);
      return;
    }
    
    // Get all text spans
    const textElements = Array.from(textLayer.querySelectorAll('span'));
    totalTextElements += textElements.length;
    
    if (!textElements || textElements.length === 0) {
      if (debug) console.log(`No text elements found in text layer for page ${pageNumber}`);
      return;
    }
    
    // For each search term
    searchTermObjects.forEach(searchTermObj => {
      const { term: searchTermLower, isWholeWord, isPhrase } = searchTermObj;
      
      // Special handling for phrases
      if (isPhrase) {
        const phraseResults = searchForPhrase(
          searchTermLower, 
          textElements, 
          pageIndex, 
          pageNumber, 
          page, 
          textLayer,
          debug
        );
        results.push(...phraseResults);
        return;
      }
      
      // Original search for single words
      textElements.forEach((element, elementIndex) => {
        const text = element.textContent || '';
        const textLower = text.toLowerCase();
        
        if (isWholeWord) {
          // For whole word matching, use regex with word boundaries
          const regex = new RegExp(`\\b${escapeRegExp(searchTermLower)}\\b`, 'gi');
          let match;
          
          while ((match = regex.exec(textLower)) !== null) {
            const index = match.index;
            try {
              // Get the bounding client rect for the element
              const elementRect = element.getBoundingClientRect();
              const textLayerRect = textLayer.getBoundingClientRect();
              
              // Calculate approximate position of the matched text
              const charWidth = elementRect.width / Math.max(text.length, 1);
              const matchLeft = elementRect.left + (index * charWidth);
              const matchWidth = searchTermLower.length * charWidth;
              
              results.push({
                pageIndex: pageIndex,
                pageNumber: pageNumber,
                text: text.substring(index, index + searchTermLower.length),
                itemIndex: elementIndex,
                charIndex: index,
                left: matchLeft - textLayerRect.left,
                top: elementRect.top - textLayerRect.top,
                width: matchWidth,
                height: elementRect.height,
                element: element,
                page: page,
                textLayer: textLayer,
                searchTerm: searchTermLower
              });
            } catch (error) {
              console.error('Error creating search result:', error);
            }
          }
        } else {
          // For non-whole word matching, use indexOf
          let startIndex = 0;
          let index;
          
          // Find all occurrences in this text element
          while ((index = textLower.indexOf(searchTermLower, startIndex)) !== -1) {
            try {
              // Get the bounding client rect for the element
              const elementRect = element.getBoundingClientRect();
              const textLayerRect = textLayer.getBoundingClientRect();
              
              // Calculate approximate position of the matched text
              const charWidth = elementRect.width / Math.max(text.length, 1);
              const matchLeft = elementRect.left + (index * charWidth);
              const matchWidth = searchTermLower.length * charWidth;
              
              results.push({
                pageIndex: pageIndex,
                pageNumber: pageNumber,
                text: text.substring(index, index + searchTermLower.length),
                itemIndex: elementIndex,
                charIndex: index,
                left: matchLeft - textLayerRect.left,
                top: elementRect.top - textLayerRect.top,
                width: matchWidth,
                height: elementRect.height,
                element: element,
                page: page,
                textLayer: textLayer,
                searchTerm: searchTermLower
              });
            } catch (error) {
              console.error('Error creating search result:', error);
            }
            
            // Move to after this occurrence
            startIndex = index + searchTermLower.length;
          }
        }
      });
    });
  });
  
  if (debug) {
    console.log(`Search complete: found ${results.length} results across ${totalTextElements} text elements`);
  }
  
  return results;
};

// Function to position search results
const positionSearchResults = (searchResults, containerElement, scale) => {
  if (!containerElement || !searchResults || searchResults.length === 0) {
    return [];
  }
  
  const containerRect = containerElement.getBoundingClientRect();
  
  const positionedResults = searchResults.map(result => {
    try {
      if (!result.page || !result.textLayer || !result.element) return null;
      
      // Get updated positions
      const pageRect = result.page.getBoundingClientRect();
      const textLayerRect = result.textLayer.getBoundingClientRect();
      
      // Calculate offsets relative to container
      const pageOffsetLeft = pageRect.left - containerRect.left + containerElement.scrollLeft;
      const pageOffsetTop = pageRect.top - containerRect.top + containerElement.scrollTop;
      
      // Special handling for phrases
      if (result.isPhrasePiece && result.matchedElements) {
        // For phrases, we should maintain the width calculation done in searchForPhrase
        return {
          ...result,
          left: pageOffsetLeft + result.left,
          top: pageOffsetTop + result.top,
          // Keep original width and height - don't recalculate
          width: result.width,
          height: result.height
        };
      } else {
        // For single words, use the standard calculation
        const elementRect = result.element.getBoundingClientRect();
        const elementLeft = elementRect.left - textLayerRect.left;
        const elementText = result.element.textContent || '';
        const charWidth = elementRect.width / Math.max(elementText.length, 1);
        
        // Calculate the position of just the matched text within the element
        const matchLeft = elementLeft + (result.charIndex * charWidth);
        const matchWidth = result.searchTerm.length * charWidth;
        
        return {
          ...result,
          left: pageOffsetLeft + matchLeft,
          top: pageOffsetTop + (elementRect.top - textLayerRect.top),
          width: matchWidth,
          height: elementRect.height
        };
      }
    } catch (error) {
      console.error('Error positioning search result:', error);
      return null;
    }
  }).filter(Boolean);
  
  return positionedResults;
};

// Completely revamped scroll function to allow free scrolling
const scrollToSearchResult = (result, containerElement) => {
  if (!result || !containerElement || !result.page) return;
  
  try {
    // Get updated positions
    const containerRect = containerElement.getBoundingClientRect();
    const elementRect = result.element.getBoundingClientRect();
    
    // Calculate scroll position to make the result visible
    const resultTop = elementRect.top - containerRect.top + containerElement.scrollTop;
    const resultBottom = resultTop + elementRect.height;
    
    // Only scroll if the result is not already visible in the viewport
    const viewportTop = containerElement.scrollTop;
    const viewportBottom = viewportTop + containerRect.height;
    
    // Check if result is already visible
    const isVisible = (resultTop >= viewportTop && resultTop <= viewportBottom) || 
                      (resultBottom >= viewportTop && resultBottom <= viewportBottom);
    
    if (!isVisible) {
      // If result not visible, scroll to it once - position at 1/4 from top for better context
      containerElement.scrollTo({
        top: resultTop - (containerRect.height / 4),
        behavior: 'smooth'
      });
    }
  } catch (err) {
    console.error('Error scrolling to search result:', err);
  }
};

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none', // Let events pass through to the PDF
    zIndex: 90,
  },
  searchContainer: {
    position: 'fixed',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: fade(theme.palette.background.paper, 0.9),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
    maxWidth: 400,
    minWidth: 400,
    transition: 'all 0.3s ease',
    pointerEvents: 'auto', // Make the search box interactive
  },
  searchField: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.background.paper, 0.9),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    boxShadow: theme.shadows[1],
  },
  resultCount: {
    marginRight: theme.spacing(1),
  },
  searchHighlight: {
    position: 'absolute',
    backgroundColor: fade(theme.palette.warning.light, 0.4),
    borderRadius: '2px',
    pointerEvents: 'none',
    transition: 'transform 0.1s ease',
    zIndex: 500,
    border: '1px solid rgba(255, 193, 7, 0.5)',
  },
  currentHighlight: {
    position: 'absolute',
    backgroundColor: fade(theme.palette.secondary.main, 0.6),
    borderRadius: '2px',
    border: `2px solid ${theme.palette.secondary.main}`,
    pointerEvents: 'none',
    zIndex: 501,
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(156, 39, 176, 0.4)',
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(156, 39, 176, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(156, 39, 176, 0)',
    },
  },
  searchButton: {
    position: 'fixed',
    backgroundColor: fade(theme.palette.background.paper, 0.9),
    zIndex: 100,
    transition: 'all 0.3s ease',
    pointerEvents: 'auto', // Make the button interactive
    boxShadow: theme.shadows[2],
    borderRadius: '50%',
    padding: '8px',
    '&:hover': {
      backgroundColor: fade(theme.palette.background.paper, 1),
      boxShadow: theme.shadows[4],
    },
  },
}));

const EnhancedPDFSearch = ({ 
  pdfDocument, 
  containerRef, 
  scale = 1.0, 
  debug = false, 
  initialSearchTerm = "",
  allPagesRendered = false
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [positionedResults, setPositionedResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showSearch, setShowSearch] = useState(Boolean(initialSearchTerm));
  const [containerBounds, setContainerBounds] = useState({ top: 0, left: 0 });
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const searchBarRef = useRef(null);
  const debounceTimeout = useRef(null);
  const initialSearchAttempts = useRef(0);
  const maxSearchAttempts = 10;
  const [uniqueMatchIds, setUniqueMatchIds] = useState([]);
  const [currentMatchIdIndex, setCurrentMatchIdIndex] = useState(0);
  const [userEnteredSearch, setUserEnteredSearch] = useState(false);
  const [searchWaiting, setSearchWaiting] = useState(false);
  
  // Update container bounds when the container reference changes or when scrolled
  useEffect(() => {
    if (containerRef?.current) {
      const updateContainerBounds = () => {
        const rect = containerRef.current.getBoundingClientRect();
        setContainerBounds({
          top: rect.top,
          left: rect.left
        });
      };
      
      // Initial update
      updateContainerBounds();
      
      const container = containerRef.current;
      
      // Use throttled handlers for better performance
      const handleScroll = () => {
        // Mark that user has scrolled manually
        setUserHasScrolled(true);
        
        if (!window.requestAnimationFrame) {
          setTimeout(updateContainerBounds, 16); // ~60fps
        } else {
          requestAnimationFrame(updateContainerBounds);
        }
      };
      
      const handleResize = () => {
        if (!window.requestAnimationFrame) {
          setTimeout(updateContainerBounds, 16);
        } else {
          requestAnimationFrame(updateContainerBounds);
        }
      };
      
      // Listen for scroll events on both document and container
      document.addEventListener('scroll', handleScroll, { passive: true });
      container.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleResize, { passive: true });
      
      return () => {
        document.removeEventListener('scroll', handleScroll);
        container.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [containerRef]);

  // Extract unique match IDs from search results, preserving reading order
  const getUniqueMatchIds = useCallback((results) => {
    if (!results || results.length === 0) return [];
    
    // First, sort the results in reading order
    const sortedResults = sortSearchResultsInReadingOrder(results);
    
    // Extract unique match IDs, preserving the order after sorting
    const uniqueIds = [];
    const seenIds = new Set();
    
    sortedResults.forEach(result => {
      if (result.matchId && !seenIds.has(result.matchId)) {
        uniqueIds.push(result.matchId);
        seenIds.add(result.matchId);
      } else if (!result.matchId) {
        // For non-multi-line results, create a unique ID based on position
        const uniqueId = `single-${result.pageIndex}-${result.itemIndex}-${result.charIndex}`;
        if (!seenIds.has(uniqueId)) {
          uniqueIds.push(uniqueId);
          seenIds.add(uniqueId);
          // Add the ID to the result for future reference
          result.matchId = uniqueId;
        }
      }
    });
    
    return uniqueIds;
  }, []);

  // Update uniqueMatchIds when positioned results change
  useEffect(() => {
    if (positionedResults.length > 0) {
      const newMatchIds = getUniqueMatchIds(positionedResults);
      
      // Only reset current index if the actual set of results has changed
      setUniqueMatchIds(prev => {
        // If match IDs changed (different search or different results), reset index
        if (prev.length !== newMatchIds.length || 
            !prev.every((id, i) => id === newMatchIds[i])) {
          setCurrentMatchIdIndex(0);
        }
        return newMatchIds;
      });
    } else {
      setUniqueMatchIds([]);
      setCurrentMatchIdIndex(0);
    }
  }, [positionedResults, getUniqueMatchIds]);

  // Perform search
  const performSearch = useCallback((term, isRetry = false) => {
    if (!term || !containerRef?.current) {
      if (!isRetry) {
        setSearchResults([]);
        setPositionedResults([]);
      }
      return 0; // Return count of results for retry logic
    }

    if (!isRetry) {
      setIsSearching(true);
      setCurrentMatchIdIndex(0);
      setUserHasScrolled(false); // Reset scroll state on new search
    }

    try {
      // Use the improved search function
      const results = searchInTextLayers(containerRef.current, term, debug);
      
      if (debug) {
        console.log(`Search for "${term}" found ${results.length} results${isRetry ? ' (retry attempt)' : ''}`);
      }
      
      // Only update if we found more results or this is the first search
      if (!isRetry || results.length > searchResults.length) {
        setSearchResults(results);
        
        // Position results
        if (results.length > 0) {
          const positioned = positionSearchResults(results, containerRef.current, scale);
          
          // Sort the positioned results
          const sortedPositioned = sortSearchResultsInReadingOrder(positioned);
          setPositionedResults(sortedPositioned);
          
          if (debug) {
            console.log(`Positioned and sorted ${sortedPositioned.length} results`);
          }
        } else {
          setPositionedResults([]);
        }
      }
      
      return results.length;
    } catch (error) {
      console.error('Error during search:', error);
      if (!isRetry) {
        setSearchResults([]);
        setPositionedResults([]);
      }
      return 0;
    } finally {
      if (!isRetry) {
        setIsSearching(false);
      }
    }
  }, [containerRef, debug, scale, searchResults.length]);

  const performInitialSearch = useCallback(() => {
    if (!initialSearchTerm || !containerRef?.current || !allPagesRendered) {
      return;
    }
    
    initialSearchAttempts.current += 1;
    
    // Start with a longer initial delay
    const baseDelay = 800;
    const attemptDelay = baseDelay * Math.pow(1.5, initialSearchAttempts.current - 1);
    
    if (debug) {
      console.log(`Search attempt ${initialSearchAttempts.current}/${maxSearchAttempts} with delay ${attemptDelay}ms`);
    }
    
    setTimeout(() => {
      const resultCount = performSearch(initialSearchTerm, initialSearchAttempts.current > 1);
      
      // If we found results, or we've tried enough times, stop retrying
      if (resultCount > 0 || initialSearchAttempts.current >= maxSearchAttempts) {
        if (debug) {
          console.log(`Search complete: found ${resultCount} results after ${initialSearchAttempts.current} attempts`);
        }
      } else {
        // Try again with a longer delay
        performInitialSearch();
      }
    }, attemptDelay);
  }, [initialSearchTerm, containerRef, allPagesRendered, debug, performSearch]);

  useEffect(() => {
    // Case 1: Handle initial search term provided via props
    if (initialSearchTerm && containerRef?.current && allPagesRendered && initialSearchAttempts.current === 0) {
      if (debug) {
        console.log("All pages rendered, beginning search attempts for initial term");
      }
      performInitialSearch();
      setSearchWaiting(false);
    } 
    // Case 2: Handle user-entered search term after pages finish rendering
    else if (searchTerm && containerRef?.current && allPagesRendered && userEnteredSearch) {
      if (debug) {
        console.log("Pages finished rendering, performing search with user-entered term:", searchTerm);
      }
      // Reset flags so we don't repeatedly search
      setUserEnteredSearch(false);
      setSearchWaiting(false);
      // Perform the search with the user's term
      performSearch(searchTerm);
    }
  }, [initialSearchTerm, containerRef, allPagesRendered, debug, performInitialSearch, searchTerm, userEnteredSearch, performSearch]);

  useEffect(() => {
    // If document finishes loading and we have a search term waiting, reset the waiting status
    if (allPagesRendered && searchWaiting) {
      setSearchWaiting(false);
    }
    
    // If document isn't loaded but we have an initial search term, show the waiting indicator
    if (!allPagesRendered && initialSearchTerm && !searchWaiting) {
      setSearchWaiting(true);
    }
  }, [allPagesRendered, searchWaiting, initialSearchTerm]);

  // Get all results for the current match
  const getCurrentMatchResults = useCallback(() => {
    if (uniqueMatchIds.length === 0 || currentMatchIdIndex < 0 || currentMatchIdIndex >= uniqueMatchIds.length) {
      return [];
    }
    
    const currentMatchId = uniqueMatchIds[currentMatchIdIndex];
    return positionedResults.filter(result => result.matchId === currentMatchId);
  }, [positionedResults, uniqueMatchIds, currentMatchIdIndex]);

  // Scroll to current result when it changes, but respect user scrolling
  useEffect(() => {
    if (uniqueMatchIds.length > 0 && containerRef?.current && currentMatchIdIndex >= 0) {
      const currentResults = getCurrentMatchResults();
      if (currentResults.length > 0 && !userHasScrolled) {
        // Scroll to the first line of the current match
        const firstLineResult = currentResults.find(r => r.isFirstLine) || currentResults[0];
        scrollToSearchResult(firstLineResult, containerRef.current);
      }
    }
  }, [currentMatchIdIndex, uniqueMatchIds, containerRef, userHasScrolled, getCurrentMatchResults]);

  // Update highlight positions
  const updatePositions = useCallback(() => {
    if (containerRef?.current && searchResults.length > 0) {
      try {
        // First position the results
        const positioned = positionSearchResults(searchResults, containerRef.current, scale);
        
        if (positioned && positioned.length > 0) {
          // Sort the positioned results before setting state
          const sortedPositioned = sortSearchResultsInReadingOrder(positioned);
          setPositionedResults(sortedPositioned);
          
          if (debug) {
            console.log(`Positioned and sorted ${sortedPositioned.length} results`);
          }
        }
      } catch (error) {
        console.error('Error positioning search results:', error);
      }
    }
  }, [containerRef, debug, scale, searchResults]);

  // Update highlight positions when container is scrolled or results change
  useEffect(() => {
    if (containerRef?.current && searchResults.length > 0) {
      updatePositions();
      
      const container = containerRef.current;
      let scrollTimeout;
      
      const handleScroll = () => {
        // Mark that user has scrolled manually
        setUserHasScrolled(true);
        
        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          updatePositions();
        }, 200);
      };
      
      container.addEventListener('scroll', handleScroll, { passive: true });
      window.addEventListener('resize', handleScroll, { passive: true });
      
      return () => {
        if (scrollTimeout) clearTimeout(scrollTimeout);
        container.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }
  }, [searchResults, containerRef, updatePositions]);

  // Toggle search panel visibility
  const toggleSearch = () => {
    const newShowSearch = !showSearch;
    setShowSearch(newShowSearch);
    
    if (!newShowSearch) {
      setSearchTerm('');
      setSearchResults([]);
      setPositionedResults([]);
    } else {
      setTimeout(() => {
        searchBarRef.current?.querySelector('input')?.focus();
      }, 100);
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setUserEnteredSearch(true); // Mark that user has manually entered a search term
    
    // If we have a search term and the document isn't fully rendered yet, show waiting status
    if (newSearchTerm && !allPagesRendered) {
      setSearchWaiting(true);
    } else {
      setSearchWaiting(false);
    }
  };

  // Debounce search term input
  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    
    if (!searchTerm) {
      setSearchResults([]);
      setPositionedResults([]);
      return;
    }
    
    debounceTimeout.current = setTimeout(() => {
      performSearch(searchTerm);
    }, 300);
    
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm, performSearch]);

  // Navigate to next result - updated for unified navigation
  const nextResult = () => {
    if (uniqueMatchIds.length === 0) return;
    
    // Reset userHasScrolled when using navigation buttons
    setUserHasScrolled(false);
    
    setCurrentMatchIdIndex(prevIndex => 
      prevIndex + 1 >= uniqueMatchIds.length ? 0 : prevIndex + 1
    );
  };

  // Navigate to previous result - updated for unified navigation
  const prevResult = () => {
    if (uniqueMatchIds.length === 0) return;
    
    // Reset userHasScrolled when using navigation buttons
    setUserHasScrolled(false);
    
    setCurrentMatchIdIndex(prevIndex => 
      prevIndex - 1 < 0 ? uniqueMatchIds.length - 1 : prevIndex - 1
    );
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      nextResult();
    }
  };

  // Clear search
  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    setPositionedResults([]);
    setCurrentMatchIdIndex(0);
    setUniqueMatchIds([]);
  };

  // Render search highlights - updated for unified navigation
  const renderHighlights = () => {
    if (!positionedResults.length) return null;
    
    // Get all results for the current match
    const currentResults = getCurrentMatchResults();
    if (!currentResults.length) return null;
    
    // Render regular highlights (all results except current match)
    const regularHighlights = positionedResults
      .filter(result => !currentResults.includes(result))
      .map(result => (
        <div
          key={`highlight-${result.pageIndex}-${result.itemIndex}-${result.charIndex}`}
          className={classes.searchHighlight}
          style={{
            left: `${result.left}px`,
            top: `${result.top}px`,
            width: `${result.width}px`,
            height: `${result.height}px`,
          }}
          title={result.text}
        />
      ));
    
    // Render current match highlights (all parts of the current match)
    const currentHighlights = currentResults.map(result => (
      <div
        key={`current-highlight-${result.pageIndex}-${result.itemIndex}-${result.charIndex}`}
        className={classes.currentHighlight}
        style={{
          left: `${result.left}px`,
          top: `${result.top}px`,
          width: `${result.width}px`,
          height: `${result.height}px`,
        }}
        title={result.text}
      />
    ));
    
    return (
      <>
        {regularHighlights}
        {currentHighlights}
      </>
    );
  };

  return (
    <>
      {/* Floating overlay for search controls */}
      <div className={classes.overlay}>
        {!showSearch ? (
          <Tooltip title="Search in document">
            <IconButton 
              className={classes.searchButton}
              onClick={toggleSearch}
              style={{
                top: `${containerBounds.top + 32}px`,
                left: `${containerBounds.left + 24}px`
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper 
            className={classes.searchContainer} 
            ref={searchBarRef}
            style={{
              top: `${containerBounds.top + 32}px`,
              left: `${containerBounds.left + 24}px`
            }}
          >
            <TextField
              className={classes.searchField}
              variant="outlined"
              size="small"
              placeholder="Search in document"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isSearching ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SearchIcon fontSize="small" />
                    )}
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end" style={{marginLeft:8}}>
                    <IconButton size="small" onClick={clearSearch}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            
            <div className={classes.buttonsContainer}>
              <div className={classes.navigationButtons}>
                {positionedResults.length > 0 ? (
                  <>
                    <Typography variant="body2" className={classes.resultCount}>
                      {currentMatchIdIndex + 1} of {uniqueMatchIds.length}
                    </Typography>
                    <Tooltip title="Previous result">
                      <IconButton 
                        size="small" 
                        onClick={prevResult} 
                        disabled={uniqueMatchIds.length === 0}
                        aria-label="Previous result"
                        color="primary"
                      >
                        <NavigateBeforeIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Next result">
                      <IconButton 
                        size="small" 
                        onClick={nextResult} 
                        disabled={uniqueMatchIds.length === 0}
                        aria-label="Next result"
                        color="primary"
                      >
                        <NavigateNextIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : searchWaiting ? (
                  <>
                    <Tooltip title="Waiting for document to load">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} thickness={4} style={{ marginRight: 8 }} />
                        <Typography variant="body2" className={classes.resultCount}>
                          Loading document...
                        </Typography>
                      </div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
              
              <IconButton size="small" onClick={toggleSearch} aria-label="Close search">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </Paper>
        )}
      </div>
      
      {/* Render highlights over the PDF */}
      {renderHighlights()}
    </>
  );
};

export default EnhancedPDFSearch;