import React from 'react';
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
// import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// import GetHelpIcon from '@material-ui/icons/HelpOutline';
import LightModeIcon from '@material-ui/icons/Brightness7';
import DarkModeIcon from '@material-ui/icons/Brightness4';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OrgSelectIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SearchIcon from '@material-ui/icons/Search';
import { WorkspaceIcon } from '../Util/Icons';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import UpcomingEventsIcon from '@material-ui/icons/Schedule';
import StatsIcon from '@material-ui/icons/Assessment';
import OrganizationsIcon from '@material-ui/icons/Domain';
import SubscriptionsIcon from '@material-ui/icons/Payment';
import UserProfileIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import UsersIcon from '@material-ui/icons/Group';
import UserActivityIcon from '@material-ui/icons/Timeline';
import ErrorEventsIcon from '@material-ui/icons/Error';
import { SupportIcon } from '../Util/Icons';
import CloseIcon from '@material-ui/icons/Close';

import {
  ProjectsIcon,
} from '../Util/Icons';

import LibraryIcon from '@material-ui/icons/LocalLibrary';

import OrgSelectDialog from './OrgSelectDialog';
import ConfigureFieldsDialog from './ConfigureFieldsDialog';
import ConfigureFolderHierarchiesDialog from './ConfigureFolderHierarchiesDialog';
import MultiUseDialog from './MultiUseDialog';
import LeadDialog from './LeadDialog';
import AppBarSearchControl from './AppBarSearchControl';
import ProgressIndicator from '../Components/ProgressIndicator';
import { GetSearchQuery } from '../Util/Search';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';
import {
  GetLightMode,
  CycleTheme,
} from '../Util/Theme';
import {
  GetOrganizationManagementPath,
  GetOrganizationManagementOrganizationPath,
  GetOrganizationSubscriptionPath,
  GetHomePath,
  GetDashboardPath,
  GetWorkspacePath,
  GetProjectsPath,
  GetSupportPath,
  GetSupportUpcomingEventsPath,
  GetSupportStatsPath,
  GetSupportOrganizationsPath,
  GetSupportOrganizationsRequiringSubscriptionPath,
  GetSupportUsersPath,
  GetSupportUserActivityPath,
  GetSupportErrorEventsPath,
  GetUserProfilePath,
  GetSearchPath,
} from '../Util/PathHelper';
import {
  CleanSearchTerms,
  GetSearchResultsClasses,
  GetContentTypeLabelFromSearchResult,
  HandleRouteToSearchResultItemByContentType,
} from '../Util/SearchResults';
import {
  GetDateValue,
  GetFieldTypeLabel,
  GetUserValue,
} from '../Util/Properties';
import {
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
} from '../Util/Task';
import {
  GetItemTypeNameFromApproval,
} from '../Util/Approvals';
import {
  GetTopPathSegment,
} from '../Util/Regex';
import API, {
  GetOrganizationSearchResultsPathForApi,
  GetUserOrganizationSearchResultsPathForApi,
} from '../Util/api';

import red from '@material-ui/core/colors/red';

const _searchComponentAsTitle_Left = 400;
export const RailTabWidth = 60;
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
  },
  nucleusOneLogo: {
    width: 32,
    marginRight:27,
    marginLeft:-10,
    [theme.breakpoints.down('xs')]: {
      marginLeft:-2,
    },
    userSelect:"none",
  },
  appBar: {
    zIndex:1300,
  },
  ribbons: {
    display:"flex",
    flexDirection:"column",
  },
  navRailAndContent: {
    display:"flex",
    overflow:"auto",
    flexGrow:1,
  },
  railTabIconContainer: {
    height:"inherit",
    width:"inherit",
    marginBottom:-8,
  },
  railTab: {
    width:RailTabWidth,
    minWidth:RailTabWidth,
    maxWidth:RailTabWidth,
    textTransform: "none",
    fontSize: 10,
    whiteSpace:"nowrap",
  },
  railTabs: {
    height:"100%",
    width:RailTabWidth,
    minWidth:RailTabWidth,
    maxWidth:RailTabWidth,
    // borderRight:"1px solid",
    // borderRightColor:(theme.palette.type === "dark") ? "#333" : "#ddd",
    backgroundColor:theme.palette.background.navRail,
    "& .MuiTabs-scrollable": {
      height:"100%",
      overflowY:"scroll",
      overflowX:"hidden",
    },
    "& .MuiTabs-flexContainerVertical": {
      height:"100%",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.background.default,
    },
  },
  content: {
    position: "relative", // For ProgressIndicator.constrained
    flexGrow:1,
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  appBarTitle: {
    maxWidth: _searchComponentAsTitle_Left - 100, // should be 100 less than search
    overflow:"hidden",
  },
  appBarSubtitle: {
    marginLeft: theme.spacing(3),
  },
  appBarProjectName: {
    marginLeft:theme.spacing(4),
    fontWeight:400,
    opacity:0.5,
  },
  appBarEnd: {
    display:"flex",

  },
  floatingTitle: {
    backgroundColor:theme.palette.background.default,
    borderRadius:4,
    paddingLeft:4,
    paddingRight:4,
    left:27-4,
    [theme.breakpoints.down('xs')]: {
      left:20-4,
    },
    userSelect: "none",
    pointerEvents:"none",
    position:"fixed",
    zIndex:1202, // To get above left panes
    bottom:18,
    opacity: 0.5
  },
  userMenu: {
    marginRight: -6,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchDesktop: {
    display:"none",
    zIndex:1,
    [theme.breakpoints.up('sm')]: {
      display: "block",
      width: 'auto',
    },
  },
  searchDesktopNormal: {
    marginLeft:0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  searchDesktopAsTitle: {
    position:"fixed",
    left:_searchComponentAsTitle_Left,
    marginLeft:0,
  },
  searchMobile: {
    color: theme.palette.primary.contrastText,
    display:"none",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  ...GetSearchResultsClasses(theme),
  desktopAppBarContent: {
    display:"none",
    [theme.breakpoints.up('sm')]: {
      display: "block",
    },
  },
  navDrawerList: {
  },
  navDrawerPaper: {
    minWidth: 250,
    maxWidth: 300,
  },
  trialButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color:(theme.palette.type === "dark") ? "#222" : theme.palette.primary.main,
    backgroundColor:(theme.palette.type === "dark") ? theme.palette.action.active : theme.palette.primary.contrastText,
  },
  nestedNavDrawerList: {
    paddingLeft: theme.spacing(3),
    paddingTop:0,
    paddingBottom:0,
  },
  switchingOrganizations: {
    height:"100%",
    width:"100%",
    backgroundColor: theme.palette.background.default,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  ribbon: {
    display:"flex",
    width:"100%",
    alignItems:"center",
    paddingLeft:IsMobile() ? 19 : 27,
    paddingRight:IsMobile() ? 19 : 29,
  },
  hasUnreadNotificationIndicator_RailIcon: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:16,
    left:36,
    zIndex:1,
  },
  hasUnreadNotificationIndicator_NavDrawerMenuItem: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:16,
    left:32,
    zIndex:1,
  },
});

class UiCore extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      auth: true,
      anchorEl: null,
      OtherAppBarContent: null,
      NavDrawerOpen: false,
      DialogDetails: {
        Open: false,
      },
      TasksMenuOpen: false,
      CaptureMenuOpen: false,
      DesignMenuOpen: false,
      ManageMenuOpen: false,
      OrgSelectMenu_MouseX: null,
      OrgSelectMenu_MouseY: null,
      SupportMenu_MouseX: null,
      SupportMenu_MouseY: null,
      AllTasksMenuOpen: false,
      SupportMenuOpen: false,
      ShowOrgSelectDialog: false,
      ShowConfigureFieldsDialog: false,
      ShowConfigureFolderGroupsDialog: false,
      ShowSwitchingOrganizationsBackground:false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorAfter2s: false,
      ShowProgressIndicatorImmediately: false,
      OrganizationMembers: new Date(),
      UserOrgRefreshKey: null,
    };

    this.AppBarBlurControlRef = React.createRef();

    this.endOfDesignMenuRef = React.createRef();
    this.endOfSupportMenuRef = React.createRef();
    this.endOfTasksMenuRef = React.createRef();
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = event => {
    this.props.history.push("/logout");
    this.handleAppBarMenuClose();
  };

  handleAppBarMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleNavDrawer = (open) => () => {
    this.setState({
      NavDrawerOpen: open,
    });
  };

  refreshUserOrganizations() {
    this.context.getUserOrganizations();
  }

  handleApiError = (err, title_optional) => {
  	if (!err.response) {
      err = {
  			response: {
  				status: 500,
  				data: {
            message: err.toString()
          }
  			}
  		};
  	}

    switch(err.response.status) {
      case 401:
      // 401: Unauthorized
      this.props.history.push({
        pathname: "/login",
        // state: { from: this.props.history.location, },
      });
      break;
      case 402:
      // 402: Payment Required
      // We use this status code to indicate the subscription for the active project is expired.
      let src = this.props.cookies.get("subscriptionRedirect");
      if (src !== undefined) {
        // Remove cookie
        this.props.cookies.remove("subscriptionRedirect");
        this.props.history.push(GetOrganizationSubscriptionPath(src));
      }
      src = this.props.cookies.get("expiredSubscriptionRedirect");
      if (src !== undefined) {
        // Remove cookie
        this.props.cookies.remove("expiredSubscriptionRedirect");
        this.props.history.push(`/expiredSubscriptions/${src}`);
      }
      break;
      case 403:
      // 403: Forbidden
      // If there's a cookie to redirect, avoid delivering error
      let rrc = this.props.cookies.get("rootRedirect");
      if (rrc !== undefined && rrc === "true") {
        // Remove cookie
        this.props.cookies.remove("rootRedirect");
        this.context.Reset();
        this.props.history.push("/");
      } else {
        let noAccessDialogDetails = {
          Title: title_optional || "Sorry, you do not have access to this item.",
          BodyText: (err.response.data.message) ? err.response.data.message : undefined,
        }
        if (err.closeCallback !== undefined) {
          noAccessDialogDetails.CloseCallback = err.closeCallback;
        }
        this.handleShowDialog(noAccessDialogDetails);
      }
      break;
      case 418:
        // This status is pushed by the server in an attempt to let the client figure things out.
      break;
      case 404:
      case 410:
      case 500:
      default:
      // 500: Internal Server Error
      let dialogDetails = {
        Title: title_optional || "Sorry, something went wrong...",
        BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      }
      if (err.closeCallback !== undefined) {
        dialogDetails.CloseCallback = err.closeCallback;
      }
      this.handleShowDialog(dialogDetails);
      break;
    }

    this.setState({
      ShowSwitchingOrganizationsBackground: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  handleSearch = fullText => {
    if (!fullText) {
      return
    }

    // This will ensure the search control loses focus and collapses
    this.AppBarBlurControlRef.focus();
    this.handleCloseDialog();
    // this.props.history.push("GetSearchPath() + "/" + btoa(fullText))
    // window.location.href = GetSearchPath() + "/" + btoa(JSON.stringify(GetSearchQuery(fullText)));
    // this.props.history.push(GetSearchPath() + "/" + btoa(unescape(encodeURIComponent(JSON.stringify(GetSearchQuery(fullText))))));
    this.props.history.push(GetSearchPath() + "/" + fullText);
    // this.props.history.push(GetSearchPath(), {SearchQuery: GetSearchQuery(fullText)});
  }

  handleShowDialog = details => {
    if (!details) {
      this.setState({DialogDetails: { Open: false }});
      return;
    }
    this.setState({
      DialogDetails: {
        Open: (details.Closed) ? false : new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        DialogWidth: details.DialogWidth,
        FullWidth: details.FullWidth,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyContent: details.BodyContent,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => {},
        CloseLabel: details.CloseLabel,
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => {},
        DisableBlurClose: (details.DisableBlurClose) ? details.DisableBlurClose : false,
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => {},
      }
    });
  }

  handleCloseDialog = () => {
    this.setState({ DialogDetails: {...this.state.DialogDetails, Open: false, }, });
  }

  handleSearchMobileClick() {
    let details = {
      // Title: "Search",
      RequireTextInput1: true,
      TextInput1Label: "Search all",
      ConfirmLabel: "GO",
      ConfirmCallback: (query) => this.handleSearch(query),
    };
    this.handleShowDialog(details);
  }

  handleOrgTitleSelect = event => {
    this.handleSetOrgSelectMenuVisibility(true)(event);
  }

  handleSetOrgSelectMenuVisibility = visible => event => {
    this.setState({
      OrgSelectMenu_MouseY: (visible) ? event.clientY : null,
      OrgSelectMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  handleOrgSelectMenuClick = organizationId => event => {
    this.handleSetOrgSelectMenuVisibility(false)();
    switch (organizationId) {
      case "manage":
        if (this.context.UserPreferences.ActiveOrganizationID) {
          this.props.history.push(GetOrganizationManagementOrganizationPath(this.context.UserPreferences.ActiveOrganizationID));  
        }
        break;
      default:
        this.handleSwitchOrganization(organizationId);
        break;
    }
  }

  handleSwitchOrganization = organizationId => {
    this.handleOrgSelectDialogClosed();
    if (this.context.UserPreferences 
      && this.context.UserPreferences.ActiveOrganizationID === organizationId) {
      return;
    }
    
    this.setState({
      ShowSwitchingOrganizationsBackground:true,
      ShowProgressIndicatorImmediately:true,
    });

    this.context.SaveUserPreferences_ActiveOrganization(organizationId)
      .then(() => {
        this.context.Reset();
        switch (GetTopPathSegment(this.props.location.pathname)) {
          case GetHomePath():
          case GetDashboardPath():
          case GetWorkspacePath():
            window.location.reload();
            break;
          case GetOrganizationManagementPath():
            this.props.history.replace(GetOrganizationManagementOrganizationPath(organizationId));
            window.location.reload();
            break;
          default:
            this.props.history.push(GetDashboardPath());
            break;
        }
      })
      .catch(this.handleApiError);
  }

  handleSetSupportMenuVisibility = visible => event => {
    this.setState({
      SupportMenu_MouseY: (visible) ? event.clientY : null,
      SupportMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  handleSupportMenuClick = id => event => {
    this.handleSetSupportMenuVisibility(false)();
    switch (id) {
      case "upcomingEvents":
        this.props.history.push(GetSupportUpcomingEventsPath());
        break;
      case "stats":
        this.props.history.push(GetSupportStatsPath());
        break;
      case "organizations":
        this.props.history.push(GetSupportOrganizationsPath());
        break;
      case "organizationsRequiringSubscription":
        this.props.history.push(GetSupportOrganizationsRequiringSubscriptionPath());
        break;
      case "users":
        this.props.history.push(GetSupportUsersPath());
        break;
      case "userActivity":
        this.props.history.push(GetSupportUserActivityPath());
        break;
      case "errorEvents":
        this.props.history.push(GetSupportErrorEventsPath());
        break;
      default:
        break;
    }
  }

  routeToCurrentOrganizationSubscription = organizationId => {
    this.props.history.push(GetOrganizationSubscriptionPath(organizationId));
  }

  routeToUserProfile = () => {
    this.props.history.push(GetUserProfilePath());
    this.handleAppBarMenuClose();
  }

  compareSearchResult = (a, b) => {
    if (a.groupId === b.groupId) {
      if (a.itemId < b.itemId) {
        return -1;
      }
    } else if (a.groupId < b.groupId) {
      return -1;
    }
  }

  groupAndSortSearchResults = searchResults => {
    let groupedSearchResults = [];
    const addSearchResult = (searchResult, groupId, itemId) => {
      searchResult.itemId = itemId;
      searchResult.groupId = groupId;
      groupedSearchResults.push(searchResult);
    }
    searchResults.forEach((sr, i) => {
      switch (sr.ContentType) {
        case "OrganizationMember":
          addSearchResult(sr, "a", i);
          break;
        case "Task":
          switch (sr.Result) {
            case "Complete":
              addSearchResult(sr, "d1", i);
              break;
            default:
              addSearchResult(sr, "d0", i);
              break;
          }
          break;
        case "Approval":
          addSearchResult(sr, "e", i);
          break;
        case "DocumentSignatureSessionRecipient":
          addSearchResult(sr, "f", i);
          break;
        case "Document":
          addSearchResult(sr, "g", i);
          break;
        case "DocumentFolder":
          addSearchResult(sr, "h", i);
          break;
        case "AssetItem":
          addSearchResult(sr, "i", i);
          break;
        case "Asset":
          addSearchResult(sr, "j", i);
          break;
        case "FormTemplate":
          addSearchResult(sr, "k", i);
          break;
        case "Process":
          addSearchResult(sr, "l", i);
          break;
        case "Field":
          addSearchResult(sr, "m", i);
          break;
        case "Project":
          addSearchResult(sr, "n", i);
          break;
        default:
          break;
      }
    });
    groupedSearchResults.sort(this.compareSearchResult);

    const updateSearchResultWithGroupHeaderWhenApplicable = searchResult => {
      const groupHeaderFinder = groupedSearchResults.filter(gsr => gsr.groupId === searchResult.groupId && gsr.IsGroupHeader);
      if (!groupHeaderFinder.length) {
        searchResult.IsGroupHeader = true;
        searchResult.GroupName = GetContentTypeLabelFromSearchResult(searchResult);
      }
      return searchResult;
    };
    return groupedSearchResults.map(updateSearchResultWithGroupHeaderWhenApplicable);
  }

  handleGetInstantSearchPromise = fullText => {
    if (!fullText) {
      return new Promise(resolve => resolve());
    }
    const { classes } = this.props;
    const uri = (this.context.UserPreferences.ActiveOrganizationMember)
      ? GetOrganizationSearchResultsPathForApi(this.context.UserPreferences.ActiveOrganizationID)
      : GetUserOrganizationSearchResultsPathForApi(this.context.UserPreferences.ActiveOrganizationID);
    return API.get(uri, { params: { searchQuery_json_base64: btoa(unescape(encodeURIComponent(JSON.stringify(GetSearchQuery(fullText))))) }})
      .then(resp => {
        if (!resp.data || !resp.data.SearchResults) {
          return null;
        }

        // Grouping results is not a good idea because it destroys the default _score desc sort.
        // const groupedAndSortedResults = this.groupAndSortSearchResults(resp.data.SearchResults);
        let resultsForOutput = [];
        // groupedAndSortedResults
        resp.data.SearchResults
          .forEach((sr, i) => {
          const groupHeaderIfApplicable = (sr.IsGroupHeader)
            ? (
              <div className={classes.searchResultGroupHeader}>
                {sr.GroupName}
                <Divider />
              </div>
            ) : null;
          const getTitle = () => {
            switch (sr.ContentType) {
              case "DocumentFolder":
                return (
                  <span style={{color:sr.DocumentFolderHexColor}}>
                    {sr.Name}
                  </span>
                );
              case "OrganizationMember":
                return GetUserValue(sr.Name, (sr.UserName) ? `${sr.UserName} (${sr.Name})` : sr.Name);
              default:
                return sr.Name;
            }
          }
          const contentTypeLabel = GetContentTypeLabelFromSearchResult(sr, true);
          const taskPriority = (sr.Priority) ? GetTaskPriorityLabel(sr.Priority) : null;
          const taskDueOn = GetDateValue(sr.DueOn);
          const taskDuration = GetTaskDurationAsString(sr.TaskDurationMultiplier, sr.TaskDurationInterval);
          const taskPrimaryDocumentName = sr.PrimaryDocument && sr.PrimaryDocument.Name;
          let allTags = [];
          if (sr.AssetItemTags && sr.AssetItemTags.length) {
            sr.AssetItemTags.forEach(t => {
              allTags.push(`${t.AssetItemName} (${t.AssetName})`)
            });
          }
          if (sr.Tags && sr.Tags.length) {
            allTags.push(sr.Tags);
          }
          resultsForOutput.push({
            searchTerms: CleanSearchTerms(fullText),
            value: sr,
            label: (
              <div className={classes.searchResultContainer}>
                {groupHeaderIfApplicable}
                <div className={classes.searchResult}>
                  <div className={(sr.ThumbnailUrl) ? classes.searchResultThumbnail : classes.searchResultNoThumbnail}>
                    {sr.ThumbnailUrl &&
                      <img src={sr.ThumbnailUrl} alt=""
                        className={classes.searchResultThumbnailImage} />
                    }
                  </div>
                  <div className={classes.searchResultText}>
                    {getTitle()}
                    <div className={classes.searchResultTextMetadata}>
                      {contentTypeLabel}
                    </div>
                    <div className={classes.searchResultTextMetadata}>
                      {
                        (sr.ProjectName && sr.ContentType !== "Project" &&
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Project</span>: {sr.ProjectName}
                          </span>
                        )
                      }
                      {
                        (sr.DocumentFolderPath &&
                          <span className={classes.searchResultTextMetadataItem} style={{color:sr.DocumentFolderHexColor}}>
                            <span className={classes.searchResultTextMetadataName}>Folder</span>: {sr.DocumentFolderPath}
                          </span>
                        )
                      }
                      {
                        (sr.ContentType === "Field" && sr.ItemType &&
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Type</span>: {GetFieldTypeLabel(sr.ItemType)}
                          </span>
                        )
                      }
                      {
                        (sr.ContentType === "Approval" && sr.ItemType &&
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Type</span>: {GetItemTypeNameFromApproval(sr)}
                          </span>
                        )
                      }
                      {
                        (sr.AssignmentUserEmail &&
                          <span className={classes.searchResultTextMetadataItem}>
                            <span style={{display:"flex",alignItems:"center"}}>
                              <span style={{marginRight:4}}>
                                <span className={classes.searchResultTextMetadataName}>Assigned to</span>:
                              </span>
                              <span>
                                {GetUserValue(sr.AssignmentUserEmail, (sr.AssignmentUserName) ? sr.AssignmentUserName : sr.AssignmentUserEmail)}
                              </span>
                            </span>
                          </span>
                        )
                      }
                      {
                        (sr.PreviewMetadata && sr.PreviewMetadata.map(m => (
                          <span key={m[0]} className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>{m[1]}</span>: {m[2]}
                          </span>
                        )))
                      }
                      {
                        (sr.ContentType === "Task" && taskPriority ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Priority</span>: {taskPriority}
                          </span>
                        ) : null)
                      }
                      {
                        (taskDueOn ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Due On</span>: {taskDueOn}
                          </span>
                        ) : null)
                      }
                      {
                        (taskDuration ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Duration</span>: {taskDuration}
                          </span>
                        ) : null)
                      }
                      {
                        (sr.ContentType === "Task" && sr.TaskMilestoneName ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Milestone</span>: {sr.TaskMilestoneName}
                          </span>
                        ) : null)
                      }
                      {
                        (sr.ContentType === "Task" && sr.TaskStateName ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Task State</span>: {sr.TaskStateName}
                          </span>
                        ) : null)
                      }
                      {
                        (allTags.length ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Tags</span>: {allTags.join(", ")}
                          </span>
                        ) : null)
                      }
                      {
                        (taskPrimaryDocumentName ? (
                          <span className={classes.searchResultTextMetadataItem}>
                            <span className={classes.searchResultTextMetadataName}>Primary Document</span>: {taskPrimaryDocumentName}
                          </span>
                        ) : null)
                      }
                    </div>
                  </div>  
                </div>
              </div>
            ),
          });
        });

        return resultsForOutput;
      })
      .catch(this.handleApiError);
  }

  handleSearchResultSelected = selectedOption => {
    if (selectedOption && selectedOption.value) {
      HandleRouteToSearchResultItemByContentType(this.props, this.context, selectedOption.value,
        null, null, null, selectedOption.searchTerms);
    }
  }

  handleThemeCycle = () => {
    this.handleAppBarMenuClose();
    CycleTheme(this.props.cookies);
  }

  handleOrgSelectDialogClosed = () => {
    this.handleSetShowOrgSelectDialogVisibility(false);
    if (this.props.onOrgSelectDialogClosed) {
      this.props.onOrgSelectDialogClosed();
    }
  }

  handleSetShowOrgSelectDialogVisibility = visible => {
    this.setState({
      NavDrawerOpen: false,
      DesignMenuOpen: false,
      ShowOrgSelectDialog: visible,
    });
  }

  handleSetShowConfigureFieldsDialogVisibility = visible => e => {
    this.toggleNavDrawer(false);
    this.setState({
      NavDrawerOpen: false,
      DesignMenuOpen: false,
      ShowConfigureFieldsDialog: visible,
    });
  }

  handleSetShowConfigureFolderGroupsDialogVisibility = visible => e => {
    this.toggleNavDrawer(false);
    this.setState({
      NavDrawerOpen: false,
      DesignMenuOpen: false,
      ShowConfigureFolderGroupsDialog: visible,
    });
  }

  handleRouteTo = (uri, windowOpenTarget) => {
    if (!uri) {
      return;
    }
    this.setState({NavDrawerOpen:false});
    if (this.props.location.pathname.startsWith(uri)) {
      return;
    } else {
      if (windowOpenTarget) {
        window.open(uri, windowOpenTarget);
      } else {
        this.props.history.push(uri);
      }
    }
  }

  handleSetOtherAppBarContent = OtherAppBarContent => {
    this.setState({OtherAppBarContent});
  }

  componentDidMount() {
    if (this.props.forcedApiError) {
      this.handleApiError(this.props.forcedApiError, this.props.apiErrorTitle);
    }
    if (typeof this.props.showProgressIndicator !== "undefined") {
      this.setState({ShowProgressIndicator: this.props.showProgressIndicator});
    }

    // Use this opportunity to check for new notifications
    if (this.context.UserPreferences 
      && this.context.UserPreferences.ActiveOrganizationID) {
      this.context.GetUserHasUnreadNotification(this.context.UserPreferences.ActiveOrganizationID);
    }

    if (this.props.onSetFuncToSetOtherAppBarContent) {
      this.props.onSetFuncToSetOtherAppBarContent(this.handleSetOtherAppBarContent);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiError !== this.props.apiError) {
      if (this.props.apiError) {
        this.handleApiError(this.props.apiError, this.props.apiErrorTitle);
      }
    }
    if (prevProps.alert !== this.props.alert) {
      this.handleShowDialog(this.props.alert);
    }
    if (prevProps.confirmation !== this.props.confirmation) {
      this.handleShowDialog(Object.assign(this.props.confirmation, { IsConfirmation: true }));
    }
    if (prevProps.showProgressIndicatorImmediately !== this.props.showProgressIndicatorImmediately) {
      let showProgressIndicator = (this.props.showProgressIndicatorImmediately === true);
      this.setState({ShowProgressIndicator: showProgressIndicator, ShowProgressIndicatorImmediately: this.props.showProgressIndicatorImmediately});
    }
    if (prevProps.showProgressIndicatorAfter2s !== this.props.showProgressIndicatorAfter2s) {
      let showProgressIndicator = (this.props.showProgressIndicatorAfter2s === true);
      this.setState({ShowProgressIndicator: showProgressIndicator, ShowProgressIndicatorAfter2s: this.props.showProgressIndicatorAfter2s});
    }
    if (prevProps.showProgressIndicator !== this.props.showProgressIndicator) {
      this.setState({ShowProgressIndicator: this.props.showProgressIndicator, ShowProgressIndicatorImmediately: false});
    }
    if (this.props.userOrgRefreshKey
      && this.props.userOrgRefreshKey !== this.state.UserOrgRefreshKey) {
      this.setState({UserOrgRefreshKey: this.props.userOrgRefreshKey});
      this.refreshUserOrganizations();
    }
  }

  handleRailTabChange = (e, RailTabValue) => {
    switch (RailTabValue) {
      case GetSupportPath():
        this.handleSetSupportMenuVisibility(true)(e);
        break;
      case GetOrganizationManagementPath():
        if (this.context.UserPreferences.ActiveOrganizationID) {
          this.handleRouteTo(GetOrganizationManagementOrganizationPath(this.context.UserPreferences.ActiveOrganizationID));
        } else {
          this.handleRouteTo(RailTabValue);
        }
        break;
      default:
        this.handleRouteTo(RailTabValue);
        break;
    }
  }

  render() {
    const {
      cookies,
      classes,
      theme,
      title,
      subtitle,
      onEditSubtitle,
      onEditTitle,
      showOrgAsTitleOnDesktop,
      orgAsTitlePreposition,
      content,
      history,
      location,
      match,
      desktopAppBarContent,
      allDevicesAppBarContent,
      hideAppBarSearchInAllDevices,
      hideAppBarSearchInMobile,
      searchComponentAsTitle,
      noSelectAppBar,
      hideNavDrawer,
      hideThemeSwitcher,
      hideSubscriptionExpirationAlert,
      organizationIdForAlerts,
      secondaryNavTabs,
      showOrgSelectDialog,
      extraContentForAppBarEnd,
      onProjectsNavClicked,
      constrainedProgressIndicator,
    } = this.props;
    const { 
      anchorEl,
      OtherAppBarContent,
      NavDrawerOpen,
      DialogDetails,
      SupportMenuOpen,
      ShowSwitchingOrganizationsBackground,
      ShowOrgSelectDialog,
      OrgSelectMenu_MouseX,
      OrgSelectMenu_MouseY,
      SupportMenu_MouseX,
      SupportMenu_MouseY,
      ShowConfigureFieldsDialog,
      ShowConfigureFolderGroupsDialog,
      ShowProgressIndicator,
      ShowProgressIndicatorAfter2s,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      UserPreferences,
      UserAvatar,
      UserOrganizations,
      OrganizationMembershipPackages,
      UserIsSupportAdmin,
      UserHasUnreadNotification,
    } = this.context;

    if (ShowSwitchingOrganizationsBackground) {
      return (
        <div className={classes.switchingOrganizations}>
          <ProgressIndicator showImmediately={true} />
          <Typography variant="h5">
            Switching organizations...
          </Typography>
        </div>
      );
    }

    let organizationIdForSubscriptionAlert = organizationIdForAlerts
      || UserPreferences.ActiveOrganizationID;
    let currentOrganizationPkgFinder = OrganizationMembershipPackages
      .filter(tp => tp.Organization.ID === organizationIdForSubscriptionAlert);
    let currentOrganizationPkg = (currentOrganizationPkgFinder.length)
      ? currentOrganizationPkgFinder[0] : null;

    const hasOrganizationAccess = (UserOrganizations && UserOrganizations.length);
    const homeListItem = (
      <ListItem button onClick={() => this.handleRouteTo(GetHomePath())}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    );
    const homeTab = (
      <Tab key="home" className={classes.railTab}
        label="Home"
        icon={
          <HomeIcon />
        }
        value={GetHomePath()}
      />
    );
    const dashboardListItem = (currentOrganizationPkg) ? (
      <ListItem button onClick={() => this.handleRouteTo(GetDashboardPath())}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    ) : null;
    const dashboardTab = (currentOrganizationPkg) ? (
      <Tab key="dashboard" className={classes.railTab}
        label="Dashboard"
        icon={
          <DashboardIcon />
        }
        value={GetDashboardPath()}
      />
    ) : null;

    const projectsListItem = (UserPreferences.ActiveOrganizationMember)
      ? (
        <ListItem button onClick={() => this.handleRouteTo(GetProjectsPath())}>
          <ListItemIcon>
            <ProjectsIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItem>
      ) : null;
    const projectsTab = (UserPreferences.ActiveOrganizationMember)
      ? (
          <Tab key="projects"
            className={classes.railTab}
            label="Projects"
            icon={
              <div className={classes.railTabIconContainer}>
                <ProjectsIcon />
              </div>
            }
            value={GetProjectsPath()}
            onClick={onProjectsNavClicked}
          />
      ) : (
          <Tab key="projects"
            className={classes.railTab}
            style={{display:"none"}}
            value={GetProjectsPath()} />
      );
    const searchTab = (UserPreferences.ActiveOrganizationMember)
      ? (
          <Tab key="search"
            className={classes.railTab}
            label="Search"
            icon={
              <div className={classes.railTabIconContainer}>
                <SearchIcon />
              </div>
            }
            value={GetSearchPath()}
          />
      ) : (
          <Tab key="search"
            className={classes.railTab}
            style={{display:"none"}}
            value={GetSearchPath()} />
      );

    const resourcesListItem = (
      <ListItem button onClick={() => this.handleRouteTo("https://nucleus.one/learn/", "n1_resourceContent")}>
        <ListItemIcon>
          <LibraryIcon />
        </ListItemIcon>
        <ListItemText primary="Resources" />
      </ListItem>
    );
    const resourcesTab = (
      <Tab key="resources"
        className={classes.railTab}
        label="Resources"
        icon={
          <div className={classes.railTabIconContainer}>
            <LibraryIcon />
          </div>
        }
        // value={"/resources"}
        onClick={() => this.handleRouteTo("https://nucleus.one/learn/", "n1_resourceContent")}
      />
    );

    const hasUnreadNotificationIndicator_workspaceListItem = (UserHasUnreadNotification)
      ? (
        <div className={classes.hasUnreadNotificationIndicator_NavDrawerMenuItem} />
      ) : (<div />);
    const workspaceListItem = (hasOrganizationAccess)
      ? (
        <ListItem button onClick={() => this.handleRouteTo(GetWorkspacePath())}>
          {hasUnreadNotificationIndicator_workspaceListItem}
          <ListItemIcon>
            <WorkspaceIcon />
          </ListItemIcon>
          <ListItemText primary="My Work" />
        </ListItem>
      ) : null;

    const hasUnreadNotificationIndicator_workspaceTab = (UserHasUnreadNotification)
      ? (
        <div className={classes.hasUnreadNotificationIndicator_RailIcon} />
      ) : (<div />);
    const workspaceTab = (hasOrganizationAccess)
        ? (
        <Tab key="workspace" className={classes.railTab}
          label="My Work"
          icon={
            <div className={classes.railTabIconContainer}>
              {hasUnreadNotificationIndicator_workspaceTab}
              <WorkspaceIcon />
            </div>
          }
          value={GetWorkspacePath()}
        />
      ) : null;

    const supportAdminListContent = (UserIsSupportAdmin)
      ? (
        <React.Fragment>
          <Divider />
          <ListItem button onClick={() => {
            let supportMenuOpen = !SupportMenuOpen;
            this.setState({SupportMenuOpen: supportMenuOpen});
            setTimeout(() => { if (supportMenuOpen) { this.endOfSupportMenuRef.scrollIntoView({behavior:"smooth"}); }}, 250);
          }}>
            <ListItemIcon>
              <SupportIcon />
            </ListItemIcon>
            <ListItemText primary="Support" />
            {SupportMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={SupportMenuOpen} timeout="auto" unmountOnExit>
            <List className={classes.nestedNavDrawerList}>

              <ListItem button onClick={this.handleSupportMenuClick("upcomingEvents")}>
                <ListItemIcon>
                  <UpcomingEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Upcoming Events" />
              </ListItem>

              <ListItem button onClick={this.handleSupportMenuClick("stats")}>
                <ListItemIcon>
                  <StatsIcon />
                </ListItemIcon>
                <ListItemText primary="Stats" />
              </ListItem>
              
              <ListItem button onClick={this.handleSupportMenuClick("organizations")}>
                <ListItemIcon>
                  <OrganizationsIcon />
                </ListItemIcon>
                <ListItemText primary="Organizations" />
              </ListItem>

              <ListItem button onClick={this.handleSupportMenuClick("organizationsRequiringSubscription")}>
                <ListItemIcon>
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText primary="Subscriptions" />
              </ListItem>

              <ListItem button onClick={this.handleSupportMenuClick("users")}>
                <ListItemIcon>
                  <UsersIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>

              <ListItem button onClick={this.handleSupportMenuClick("userActivity")}>
                <ListItemIcon>
                  <UserActivityIcon />
                </ListItemIcon>
                <ListItemText primary="User Activity" />
              </ListItem>

              <ListItem button onClick={this.handleSupportMenuClick("errorEvents")}>
                <ListItemIcon>
                  <ErrorEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Error Events" />
              </ListItem>

              <span ref={instance => this.endOfSupportMenuRef = instance} />
            </List>
          </Collapse>
        </React.Fragment>
      ) : null;

    const organizationBasedListContent = (hasOrganizationAccess)
      ? (
        <React.Fragment>
          {homeListItem}
          {dashboardListItem}
          {workspaceListItem}
          {projectsListItem}
          <Divider />
        </React.Fragment>
      ) : null;

    let activeOrganizationNameForOrgSelect = null;
    const activeOrgFinder = UserOrganizations.filter(uo => uo.OrganizationID === UserPreferences.ActiveOrganizationID);
    if (activeOrgFinder.length) {
        activeOrganizationNameForOrgSelect = activeOrgFinder[0].OrganizationName;
    };
    if (!activeOrganizationNameForOrgSelect) {
      activeOrganizationNameForOrgSelect = "Select an organization...";
    }
    const orgSelectListContent = (activeOrganizationNameForOrgSelect)
      ? (
        <ListItem button onClick={() => this.handleSetShowOrgSelectDialogVisibility(true)}>
          <ListItemIcon>
            <OrganizationsIcon />
          </ListItemIcon>
          <ListItemText primary={activeOrganizationNameForOrgSelect} style={{flexGrow:0}} />
          {/*<ArrowDropDownIcon style={{marginLeft:theme.spacing(1)}} />*/}
        </ListItem>
      ) : null;

    const navDrawerList = (
      <div className={classes.navDrawerList}>
        <Button onClick={() => this.handleRouteTo("/home")} style={{width:"100%"}}>
          <img style={{width: 120,margin:"0 -15px -10px -15px"}} src="/nucleusOne.png" alt="Dashboard" />
        </Button>
        <Divider />
        {orgSelectListContent}
        <Divider />

        {organizationBasedListContent}
        {resourcesListItem}
        {supportAdminListContent}
      </div>
    );

    const configureFieldsDialog = (ShowConfigureFieldsDialog)
      ? (
        <ConfigureFieldsDialog 
          open={ShowConfigureFieldsDialog}
          onApiError={this.handleApiError}
          closeCallback={this.handleSetShowConfigureFieldsDialogVisibility(false)}
        />
      ) : null;

    const configureFolderGroupsDialog = (ShowConfigureFolderGroupsDialog)
      ? (
        <ConfigureFolderHierarchiesDialog 
          open={ShowConfigureFolderGroupsDialog}
          onApiError={this.handleApiError}
          closeCallback={this.handleSetShowConfigureFolderGroupsDialogVisibility(false)} />
      ) : null;

    const editIcon = (<EditIcon style={{
      fontSize: (IsMobile()) ? 16 : 12,
      marginLeft: (!IsMobile()) ? theme.spacing(1) / 2 : undefined,
      marginRight: (IsMobile()) ? theme.spacing(1) / 2 : undefined,
    }} />);
    const editSubtitleIcon = (onEditSubtitle) ? (!IsMobile()) ? (<sup>{editIcon}</sup>) : editIcon : null;
    let subtitleComponent = (subtitle)
      ? (
        <Typography variant="subtitle1" color="inherit"
          className={classes.appBarSubtitle}
          style={onEditSubtitle && { cursor: "pointer"}}
          onClick={(e) => {if (onEditSubtitle) onEditSubtitle(e);}}>
          {(IsMobile()) ? editSubtitleIcon : null}
          {subtitle}
          {(!IsMobile()) ? editSubtitleIcon : null}
        </Typography>
      ) : null;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately || ShowProgressIndicatorAfter2s) {
      progressIndicator = (
        <ProgressIndicator constrained={constrainedProgressIndicator} showImmediately={ShowProgressIndicatorImmediately} showAfter2s={ShowProgressIndicatorAfter2s} />
      );
    }

    let searchComponent = [];
    let mobileSearchComponent = null;
    if (hasOrganizationAccess && !hideAppBarSearchInAllDevices) {
      searchComponent.push(
        <div
          className={classNames(classes.searchDesktop, 
            (searchComponentAsTitle) ? classes.searchDesktopAsTitle : classes.searchDesktopNormal,
          )}
          key="searchDesktop">
          <AppBarSearchControl
            onGetOptionsFilterPromise={this.handleGetInstantSearchPromise} 
            value={null /* This prevents the control from receiving a value */}
            location={location}
            history={history}
            showAdvancedSearchButton={UserPreferences.ActiveOrganizationMember}
            onValueChange={this.handleSearchResultSelected}
            onKeyDown={e => { if (e.keyCode === 13) { this.handleSearch(e.target.value); } }}
          />
          <input
            ref={instance => this.AppBarBlurControlRef = instance}
            style={{
              zIndex:0,
              position:"absolute",
              opacity:0,
            }}
          />
        </div>
      );

      mobileSearchComponent = (!hideAppBarSearchInMobile)
        ? (
          <IconButton className={classes.searchMobile} aria-label="Search" key="searchMobile"
            onClick={() => this.handleSearchMobileClick()}>
            <SearchIcon />
          </IconButton>
        ) : null;
    }

    let ribbons = [];
    if (!hideSubscriptionExpirationAlert) {
      if (currentOrganizationPkg && currentOrganizationPkg.IsAdmin && (currentOrganizationPkg.IsExpired || currentOrganizationPkg.IsExpiringSoon)) {
        const subscriptionButtonDiv = (GetOrganizationSubscriptionPath(currentOrganizationPkg.Organization.ID) !== match.url)
          ? (
            <div style={{paddingLeft:theme.spacing(3)}}>
              <Button variant="contained" size="small"
                onClick={() => this.routeToCurrentOrganizationSubscription(currentOrganizationPkg.Organization.ID)}
              >
                MANAGE
              </Button>
            </div>
          ) : null;
        ribbons.push(
          <div key="subscriptionRibbon" className={classes.ribbon}
            style={{
              backgroundColor: red[300],
              minHeight: 48,
            }}
          >
            <div>
              {`Subscription ${currentOrganizationPkg.IsExpired ? "is expired" : "will expire soon"}. Act now!`}
            </div>
            {subscriptionButtonDiv}
          </div>
        );
      }
    }

    // For sorting organizations
    const compareOrganizations = (a, b) => {
      if (a.NameLower < b.NameLower) {
        return -1;
      }
    };
    let orgMenuItems = []
    if (showOrgAsTitleOnDesktop && UserOrganizations) {
      orgMenuItems = UserOrganizations.sort(compareOrganizations).map(uo =>
        <MenuItem key={uo.OrganizationID} onClick={this.handleOrgSelectMenuClick(uo.OrganizationID)}>
          {uo.OrganizationName}
        </MenuItem>
      )
    }
    orgMenuItems.push(
      <Divider key="divider" />,
      <MenuItem key="manage" onClick={this.handleOrgSelectMenuClick("manage")}>
        Manage Organizations
      </MenuItem>
    );
    const orgSelectMenu = (showOrgAsTitleOnDesktop)
      ? (
        <Menu
          id="orgSelectMenu"
          keepMounted
          open={OrgSelectMenu_MouseY !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            OrgSelectMenu_MouseY !== null && OrgSelectMenu_MouseX !== null
              ? { top: OrgSelectMenu_MouseY, left: OrgSelectMenu_MouseX }
              : undefined
          }
          onClose={this.handleSetOrgSelectMenuVisibility(false)}
        >
          {orgMenuItems}
        </Menu>
      ) : null;

    const userMenuOpen = Boolean(anchorEl);
    const editTitleIcon = (onEditTitle) ? (!IsMobile()) ? (<sup>{editIcon}</sup>) : editIcon : null;
    const preposition = orgAsTitlePreposition || " in ";
    const titleOrOrgAsTitle = (showOrgAsTitleOnDesktop)
      ? (
        <div style={{
          display:"flex",
          alignItems:"center",
          marginLeft:4, // assists in keylines, particularly /projects and /workspace
        }}>
          {`${title}${(activeOrgFinder.length) ? " " + preposition + " " + activeOrgFinder[0].OrganizationName : ""}`}
          <OrgSelectIcon style={{
            marginLeft:theme.spacing(1),
            color:"rgba(255,255,255,0.5)",
          }} />
        </div>
      )
      : title;
    const titleComponent = (!searchComponentAsTitle)
      ? (
        <div className={classes.appBarTitleContainer}>
          <Typography variant="h6" color="inherit"
            style={{
              cursor: (onEditTitle || showOrgAsTitleOnDesktop) ? "pointer" : undefined,
              marginLeft: (hideNavDrawer) ? 3 : undefined,
              userSelect: "none",
            }}
            onClick={e => {
              if (onEditTitle) {
                onEditTitle(e);
              } else if (showOrgAsTitleOnDesktop) {
                this.handleOrgTitleSelect(e);
              }
            }}>
            {(IsMobile()) ? editTitleIcon : null}
            {titleOrOrgAsTitle}
            {(!IsMobile()) ? editTitleIcon : null}
          </Typography>
          {subtitleComponent}
          {/*projectNameComponent*/}
          {orgSelectMenu}
          {OtherAppBarContent}
        </div>
      ) : null;

    const searchAsTitleComponent = (searchComponentAsTitle)
      ? (
        <div className={classes.appBarTitleContainer} style={{overflow:"visible"}}>
          <Typography variant="h6" color="inherit" className={classes.appBarTitle}>
            {title}
          </Typography>
          {searchComponent}
        </div>
      ) : null;

    const userProfileMenuItem = (UserPreferences.UserEmail)
      ? (
        <MenuItem key="item_userprofile" onClick={this.routeToUserProfile}>
          <ListItemIcon>
            <UserProfileIcon />
          </ListItemIcon>
          User Profile
        </MenuItem>
      ) : null;

    const supportMenuItems = [
      <MenuItem key="support_upcomingEvents" onClick={this.handleSupportMenuClick("upcomingEvents")}>
        <ListItemIcon>
          <UpcomingEventsIcon />
        </ListItemIcon>
        <ListItemText primary="Upcoming Events" />
      </MenuItem>,
      <MenuItem key="support_stats" onClick={this.handleSupportMenuClick("stats")}>
        <ListItemIcon>
          <StatsIcon />
        </ListItemIcon>
        <ListItemText primary="Stats" />
      </MenuItem>,
      <MenuItem key="support_organizations" onClick={this.handleSupportMenuClick("organizations")}>
        <ListItemIcon>
          <OrganizationsIcon />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
      </MenuItem>,
      <MenuItem key="support_organizationsRequiringSubscription" onClick={this.handleSupportMenuClick("organizationsRequiringSubscription")}>
        <ListItemIcon>
          <SubscriptionsIcon />
        </ListItemIcon>
        <ListItemText primary="Subscriptions" />
      </MenuItem>,
      <MenuItem key="support_users" onClick={this.handleSupportMenuClick("users")}>
        <ListItemIcon>
          <UsersIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </MenuItem>,
      <MenuItem key="support_userActivity" onClick={this.handleSupportMenuClick("userActivity")}>
        <ListItemIcon>
          <UserActivityIcon />
        </ListItemIcon>
        <ListItemText primary="User Activity" />
      </MenuItem>,
      <MenuItem key="support_errorEvents" onClick={this.handleSupportMenuClick("errorEvents")}>
        <ListItemIcon>
          <ErrorEventsIcon />
        </ListItemIcon>
        <ListItemText primary="Error Events" />
      </MenuItem>
    ];
    const supportMenu = (
      <Menu
        id="supportMenu"
        keepMounted
        open={SupportMenu_MouseY !== null}
        anchorReference="anchorPosition"
        anchorPosition={
          SupportMenu_MouseY !== null && SupportMenu_MouseX !== null
            ? { top: SupportMenu_MouseY, left: SupportMenu_MouseX }
            : undefined
        }
        onClose={this.handleSetSupportMenuVisibility(false)}
      >
        {supportMenuItems}
      </Menu>
    );

    const supportTab = (UserIsSupportAdmin)
      ? (
        <Tab key="support" className={classes.railTab}
          label="Support"
          icon={
            <SupportIcon />
          }
          value={GetSupportPath()}
        />
      ) : null;

    const manageOrgsTab = (
      <Tab key="manageOrgs" className={classes.railTab}
        style={{whiteSpace:"normal"}}
        label="Manage Orgs"
        icon={
          <OrganizationsIcon />
        }
        value={GetOrganizationManagementPath()}
      />
    );

    // Placing a tab at the top of the list that matches the current location
    // prevents warnings when there is no real tab for the location 
    const alwaysMatchesTab = (
      <Tab key="dummy" value={GetTopPathSegment(this.props.location.pathname)} style={{display:"none"}} />
    );

    const desktopNavRail = (!hideNavDrawer && !IsMobile())
      ? (
        <Tabs
          value={GetTopPathSegment(this.props.location.pathname)}
          className={classes.railTabs}
          variant="scrollable"
          orientation="vertical"
          onChange={this.handleRailTabChange}
        >
          {alwaysMatchesTab}
          {homeTab}
          {dashboardTab}
          {workspaceTab}
          {projectsTab}
          {searchTab}

          <Tab key="growTab" disabled style={{
            flexGrow: 1,
          }} />

          {resourcesTab}
          {manageOrgsTab}
          {supportTab}
        </Tabs>
      ) : null;

    const mobileNavDrawer = (!hideNavDrawer && IsMobile())
      ? (
        <SwipeableDrawer
          classes={{ paper: classes.navDrawerPaper }}
          open={NavDrawerOpen}
          onClose={this.toggleNavDrawer(false)}
          onOpen={this.toggleNavDrawer(true)}
        >
          <div
            tabIndex={-1}
            role="button"
            // onClick={this.toggleNavDrawer(false)}
            // onKeyDown={this.toggleNavDrawer(false)}
          >
            {navDrawerList}
          </div>
        </SwipeableDrawer>
      ) : null;

    const themeUserMenuItem = (!hideThemeSwitcher)
      ? (
        <MenuItem key="themeSwitcher" onClick={() => this.handleThemeCycle()}>
          <ListItemIcon>
            {GetLightMode(cookies) ? <DarkModeIcon /> : <LightModeIcon />}
          </ListItemIcon>
          {GetLightMode(cookies) ? "Dark Mode" : "Light Mode"}
        </MenuItem>
      ) : null

    const mobileNavDrawerButton = (IsMobile())
      ? (
        <IconButton 
          edge="start"
          className={classes.menuButton}
          color="inherit" aria-label="Open drawer"
          onClick={this.toggleNavDrawer(true)}>
          <MenuIcon />
        </IconButton>
      ) : null;

    const desktopNucleusOneLogoButton = (!IsMobile())
      ? (
        <img className={classes.nucleusOneLogo}
          src="/n1m.png"
          alt="Nucleus One Logo"
          style={{cursor:"pointer"}}
          onClick={() => this.handleRouteTo(GetHomePath())}
        />
      ) : null;

    const closeButton = (window.opener)
      ? (
        <IconButton 
          edge="end"
          color="inherit" aria-label="Close tab"
          onClick={() => window.close()}
        >
          <CloseIcon />
        </IconButton>
      ) : null;

    let toolBarContent = (!hideNavDrawer)
      ? (
        <React.Fragment>
        {mobileNavDrawerButton}
        {desktopNucleusOneLogoButton}

        {(searchComponentAsTitle) ? searchAsTitleComponent : titleComponent}
        {/*{titleComponent}*/}

        <div className={classes.desktopAppBarContent}>
          {desktopAppBarContent}
        </div>
        <div>
          {allDevicesAppBarContent}
        </div>
        
        {(!searchComponentAsTitle) ? searchComponent : null}
        {mobileSearchComponent}

        <div className={classes.userMenu}>
          <IconButton
            aria-owns={userMenuOpen ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            size="small"
            onClick={this.handleMenu}
            color="inherit"
          >
            {UserAvatar}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={userMenuOpen}
            onClose={this.handleAppBarMenuClose}
          >
            {userProfileMenuItem}
            {themeUserMenuItem}
            {/*{getHelpMenuItem}*/}
            <MenuItem onClick={this.handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        </div>
        </React.Fragment>
      )
      : (
        <React.Fragment>
          {titleComponent}
          <div className={classes.appBarEnd}>
            {extraContentForAppBarEnd}
            {closeButton}
          </div>
        </React.Fragment>
      );

    const orgSelectDialog = (
      <OrgSelectDialog
        history={history}
        match={match}
        open={ShowOrgSelectDialog || showOrgSelectDialog || false}
        onApiError={this.handleApiError}
        onClose={() => this.handleOrgSelectDialogClosed()}
        onSwitchOrganization={this.handleSwitchOrganization}
      />
    );

    const leadDialog = (UserPreferences.QueryLeadData) ? (
      <LeadDialog open onApiError={this.handleApiError} />
    ) : null;

    return (
      <div className={classes.root}>

        <AppBar position="static"
          className={classes.appBar}
          style={{
            backgroundColor: theme.palette.background.appBar,
            userSelect: (noSelectAppBar) ? "none" : undefined,
          }}>
          <Toolbar>
            {toolBarContent}
          </Toolbar>
          {secondaryNavTabs}
        </AppBar>

        {mobileNavDrawer}

        {/*{floatingProjectName}*/}
        {/*{floatingOrganizationName}*/}

        <MultiUseDialog Details={DialogDetails} />
        {orgSelectDialog}
        {configureFieldsDialog}
        {configureFolderGroupsDialog}
        {supportMenu}
        
        <div className={classes.ribbons}>
          {ribbons}
        </div>

        <div className={classes.navRailAndContent}>
          {desktopNavRail}
          <div className={classes.content}>
            {content}
            {leadDialog}
            {progressIndicator}
          </div>
        </div>

      </div>
    );
  }
}

UiCore.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetFuncToSetOtherAppBarContent: PropTypes.func,
  onProjectsNavClicked: PropTypes.func,
  extraContentForAppBarEnd: PropTypes.object,
  orgAsTitlePreposition: PropTypes.string,
  constrainedProgressIndicator: PropTypes.bool,
};

export default withRouter(withCookies(withStyles(styles, { withTheme: true })(UiCore)));